<template>
  <div id="antdVue" class="antdVue" :style="themeColorInit(themeColorConfig)">
    <!---栅格布局 布局开始-->
    <a-row class="benben-grid  heartdataStatisticsboard_flex_0">

      <a-col :span="24" class="heartdataStatisticsboard_fd0_0">
        <div class="flex align-center heartdataStatisticsboard_fd0_0_c0" isAlone="false">
          <div class="flex flex-direction flex-wrap align-center self-center heartdataStatisticsboard_fd0_0_c0_c0" isAlone="false">

            <span>待我处理</span>

            <span class="heartdataStatisticsboard_fd0_0_c0_c0_c1">24</span>

            <span class="heartdataStatisticsboard_fd0_0_c0_c0_c2">昨日5396</span>

          </div>

          <div class="flex align-center heartdataStatisticsboard_fd0_0_c0_c1" isAlone="false">
            <div class="flex flex-direction flex-wrap align-center self-center heartdataStatisticsboard_fd0_0_c0_c1_c0" isAlone="false">

              <span>待我处理</span>

              <span class="heartdataStatisticsboard_fd0_0_c0_c1_c0_c1">24</span>

              <span class="heartdataStatisticsboard_fd0_0_c0_c1_c0_c2">这是文字</span>

            </div>

          </div>

          <div class="flex align-center heartdataStatisticsboard_fd0_0_c0_c2" isAlone="false">
            <div class="flex flex-direction flex-wrap align-center self-center heartdataStatisticsboard_fd0_0_c0_c2_c0" isAlone="false">

              <span>待我处理</span>

              <span class="heartdataStatisticsboard_fd0_0_c0_c2_c0_c1">24</span>

              <span class="heartdataStatisticsboard_fd0_0_c0_c2_c0_c2">这是文字</span>

            </div>

          </div>

          <div class="flex align-center heartdataStatisticsboard_fd0_0_c0_c3" isAlone="false">
            <div class="flex flex-direction flex-wrap align-center self-center heartdataStatisticsboard_fd0_0_c0_c3_c0" isAlone="false">

              <span>待我处理</span>

              <span class="heartdataStatisticsboard_fd0_0_c0_c3_c0_c1">24</span>

              <span class="heartdataStatisticsboard_fd0_0_c0_c3_c0_c2">这是文字</span>

            </div>

          </div>

          <div class="flex align-center heartdataStatisticsboard_fd0_0_c0_c4" isAlone="false">
            <div class="flex flex-direction flex-wrap align-center self-center heartdataStatisticsboard_fd0_0_c0_c4_c0" isAlone="false">

              <span>待我处理</span>

              <span class="heartdataStatisticsboard_fd0_0_c0_c4_c0_c1">24</span>

              <span class="heartdataStatisticsboard_fd0_0_c0_c4_c0_c2">这是文字</span>

            </div>

          </div>

          <div class="flex align-center heartdataStatisticsboard_fd0_0_c0_c5" isAlone="false">
            <div class="flex flex-direction flex-wrap align-center self-center heartdataStatisticsboard_fd0_0_c0_c5_c0" isAlone="false">

              <span>待我处理</span>

              <span class="heartdataStatisticsboard_fd0_0_c0_c5_c0_c1">24</span>

              <span class="heartdataStatisticsboard_fd0_0_c0_c5_c0_c2">这是文字</span>

            </div>

          </div>

        </div>

      </a-col>

      <a-col :span="18">

      </a-col>

      <a-col :span="6">

      </a-col>

    </a-row>
    <!---栅格布局 布局结束-->

  </div>
</template>

<script>
    import {
        receive,
        benbenSelectDiy,
        benbenFlexNumberBox,
        scrollList,
        treeSelect,
        simpleSelect,
        multipleSelect,
        cascaderForm,
        checkboxForm,
        switchForm,
        messageReply,
        rangePicker,
        handSignature,
        mixinAddTableItem,
        editorForm,
        qiunDataCharts,
        copyText,
        mixinTableItemChange,
        in_array,
        themeColorInit,
        getUrlKey,
        clearDataPass,
        isEmpty
    } from '@/assets/diypaaspc/diy-pass-pc.js'
    import {
        provinceForm
    } from '@/assets/diypaaspc/province_area.js'
    import request from '@/utils/request'
    export default {
        name: 'Goods',
        components: {
            qiunDataCharts,
            benbenSelectDiy,
            //            productSelect,
            benbenFlexNumberBox,
            scrollList,
            treeSelect,
            simpleSelect,
            messageReply,
            rangePicker,
            multipleSelect,
            provinceForm,
            editorForm,
            cascaderForm,
            switchForm,
            checkboxForm,
            //            uploadImage,
            //            uploadFile,
            //            uploadMedia,
            //            uploadSingleFile,
            handSignature
        },
        data() {
            receive(this)
            const themeColorConfig = {
                'presetApi': {
                    'getClassifyOne': '',
                    'getClassifyTow': '',
                    'getClassifyShop': ''
                },
                'themeType': 0,
                'themeConfig': [{
                    'colorConfig': ['#333333', '#666666', '#999999', '#ffffff', '#1178F0', '#F02325', '#FC9C3A', '#4DC777', '#F2CC98', '#F2CC66'],
                    'bgColorConfig': ['#EBEDF8', '#FFFFFF', '#1178F0', '#FC9C3A', '#FC9fff', '#E6F333', '#E6F1FE', 'linear-gradient(301deg, #EBBA7B 0%, #F8D9AD 100%)', 'linear-gradient(180deg, #F5588E 0%, #F0403C 100%)', 'linear-gradient(179deg, #FFD033 0%, #F4890E 100%)'],
                    'bdColorConfig': ['#EBEDF8', '#FFFFFF', '#1178F0', '#FC9C3A', '#333333', '#666666', '#999999', '#dddddd', '#1178F0', '#F02325']
                }]
            }
            return {
                themeColorConfig,
                'num': 1000
            }
        },
        computed: {

        },
        created() {

        },
        mounted() {

        },
        beforeDestroy() {

        },
        watch: {},
        methods: {
            copyText,
            mixinTableItemChange,
            in_array,
            themeColorInit,
            isEmpty

        }
    }
</script>

<style lang="less" scoped>
    @import 'https://zhongtai.zhongbenruanjian.com/static/diypaaspc/css/diy-pass-pc.css';

    .antdVue {
        width: 100%;
        background: #F8F8F8;
        background-size: 100% auto !important;
    }

    #antdVue .heartdataStatisticsboard_flex_0 {
        background: #fff;
        width: 100%;
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c5_c0_c2 {
        color: var(--benbenFontColor2);
        font-size: 12px;
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c5_c0_c1 {
        font-size: 22px;
        margin: 5px 0px 0px 0px;
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c5_c0 {
        width: 100%;
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c5 {
        background: rgba(245, 250, 254, 1);
        width: 100%;
        background-size: 100% auto !important;
        border-radius: 8px 8px 8px 8px;
        padding: 10px 0px 10px 0px;
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c4_c0_c2 {
        font-weight: 400;
        font-size: 12px;
        color: var(--benbenFontColor2);
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c4_c0_c1 {
        font-size: 22px;
        margin: 5px 0px 0px 0px;
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c4_c0 {
        width: 100%;
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c4 {
        background: rgba(245, 250, 254, 1);
        width: 100%;
        background-size: 100% auto !important;
        border-radius: 8px 8px 8px 8px;
        padding: 10px 0px 10px 0px;
        margin: 0px 10px 0px 0px;
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c3_c0_c2 {
        color: var(--benbenFontColor2);
        font-size: 12px;
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c3_c0_c1 {
        font-size: 22px;
        margin: 5px 0px 0px 0px;
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c3_c0 {
        background: var(--benbenbgColor1);
        width: 100%;
        background-size: 100% auto !important;
        padding: 10px 0px 10px 0px;
        border-radius: 10px 10px 10px 10px;
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c3 {
        width: 100%;
        border-radius: 8px 8px 8px 8px;
        margin: 0px 10px 0px 0px;
        padding: 10px 0px 10px 0px;
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c2_c0_c2 {
        font-size: 12px;
        font-style: normal;
        color: var(--benbenFontColor2);
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c2_c0_c1 {
        font-size: 22px;
        margin: 5px 0px 0px 0px;
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c2_c0 {
        width: 100%;
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c2 {
        background: rgba(245, 250, 254, 1);
        width: 100%;
        background-size: 100% auto !important;
        text-align: center;
        padding: 10px 0px 10px 0px;
        border-radius: 10px 10px 10px 10px;
        margin: 10px 10px 10px 10px;
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c1_c0_c2 {
        font-size: 12px;
        color: var(--benbenFontColor2);
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c1_c0_c1 {
        font-size: 22px;
        margin: 5px 0px 0px 0px;
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c1_c0 {
        background: var(--benbenbgColor1);
        width: 100%;
        margin: 0px 10px 0px 0px;
        border-radius: 10px 10px 10px 10px;
        background-size: 100% auto !important;
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c1 {
        background: var(--benbenbgColor1);
        width: 100%;
        background-size: 100% auto !important;
        border-radius: 8px 8px 8px 8px;
        padding: 10px 0px 10px 0px;
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c0_c2 {
        font-size: 12px;
        color: var(--benbenFontColor2);
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c0_c1 {
        font-size: 22px;
        margin: 5px 0px 0px 0px;
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0_c0 {
        background: var(--benbenbgColor1);
        width: 100%;
        background-size: 100% auto !important;
        border-radius: 10px 10px 10px 10px;
        margin: 0px 10px 0px 0px;
        padding: 10px 0px 10px 0px;
    }

    #antdVue .heartdataStatisticsboard_fd0_0_c0 {
        width: 100%;
        border-radius: 8px 8px 8px 8px;
        padding: 10px 10px 0px 10px;
    }

    #antdVue .heartdataStatisticsboard_fd0_0 {
        background: url(https://zhongben-crm.oss-cn-zhangjiakou.aliyuncs.com/uploads/images/20230614/13b4effb4029d7e015dcf797d6118043.png) no-repeat;
        background-size: 100% auto !important;
    }
</style>
